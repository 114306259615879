import React, { useState, FC } from 'react';
import styled from '@emotion/styled';
import { withPrefix } from 'gatsby';
import { Location } from '@reach/router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import { login } from '../auth';
import { AuthViewerQuery } from '../generated/graphql';
import { logger } from '../logger';

const authQuery = gql`
  query authViewer {
    viewer {
      admin {
        id
        email
      }
    }
  }
`;

const LoginButton = styled.a`
  margin-top: 20px;
  background: #663399;
  color: white;
  padding: 10px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: #7d4ab0;
  }
`;

const ErrorBox = styled.div`
  border: 1px solid red;
  padding: 10px;
  margin: 10px;
  color: red;
`;
type authState = 'noToken' | 'checkingAuth';

interface AuthError {
  message: string;
  description?: string;
}

interface AuthPageProps {
  location: Location;
}

const AuthPage: FC<AuthPageProps> = ({ location }) => {
  const [authState, setAuthState] = useState<authState>('noToken');
  const [authError, setAuthError] = useState<AuthError | undefined>();
  const queryState = useQuery<AuthViewerQuery>(authQuery);

  if (queryState.loading) return <p>Loading...</p>;
  if (queryState.error) return <p>Error: {queryState.error}</p>;
  // if already logged-in, go to the homepage
  if (queryState.data?.viewer?.admin) {
    window.location.href = withPrefix('/');
  }

  const redirect = (location.state as any)?.redirect as string | undefined;

  const onClickLogin = async () => {
    try {
      setAuthState('checkingAuth');
      await login();
      window.location.href = redirect || withPrefix('/');
    } catch (error) {
      setAuthError({ message: error.message, description: error.description });
      setAuthState('noToken');
      logger.error('Failed to log in', { error });
    }
  };

  const renderLoginButton = () => {
    return (
      <>
        <h1>Login</h1>
        <LoginButton onClick={onClickLogin}>Log in with OAuth</LoginButton>
      </>
    );
  };

  const renderAuthorizing = () => <p>Authorizing...</p>;

  return (
    <IndexLayout>
      <Page>
        <Container>
          {authError && (
            <ErrorBox>
              <h4>Auth Error</h4>
              <p>{authError.message}</p>
              <p>{authError.description}</p>
            </ErrorBox>
          )}
          {authState === 'noToken' && renderLoginButton()}
          {authState === 'checkingAuth' && renderAuthorizing()}
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default AuthPage;
